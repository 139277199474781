import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './get-in-touch.module.scss'
const getInTouch = () => {
  const fieldClassName = `px-3 py-2 mb-3 rounded-md ${styles.field}`;

  return (
    <Layout>
      <SEO title='Get In Touch' />
      <div className='text-center'>
        <div>
          <div className={`${styles.img}`} alt='Get In Touch'></div>
          <div className-={`${styles.h1}`}>
            <h1 className='mb-8'>Get In Touch</h1>
            <p className='mb-12'>If you're a brand looking to realise social commerce</p>
          </div>
        </div>

      <form name='Get In Touch' method='POST' data-netlify='true'>
        <div className={`flex flex-col ${styles.form} items-center`}>
          <input type="hidden" name="form-name" value="Get In Touch" />
          <input
            className={fieldClassName}
            type='text'
            name='company-name'
            placeholder='Company name'
          >
          </input>
          <input
            className={fieldClassName}
            type='text'
            name='website'
            placeholder='Website'
          >
          </input>
          <input
            className={fieldClassName}
            type='text'
            name='full-name'
            placeholder='Full name'
          >
          </input>
          <input
            className={fieldClassName}
            type='text'
            name='position'
            placeholder='Position in company'
          >
          </input>
          <input
            className={fieldClassName}
            type='text'
            name='email'
            placeholder='Email'
          >
          </input>
          <input
            className={fieldClassName}
            type='text'
            name='contact-number'
            placeholder='Contact number'
          >
          </input>
        </div>
        <button type='submit' className='bg-black hover:bg-gray-700 text-white font-bold text-sm uppercase py-2 px-16 rounded-sm my-3'>
          get in touch
        </button>
      </form>
      </div>
    </Layout>
  )
}

export default getInTouch
